import { useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import type { ShopGroupAggregateMachinery } from '~/layers/customer-module/types'
import type { ShopMachineryFiltersInput } from '~/server/trpc/routers/shop/machinery'
import type { ShopMachineryAccessoryFiltersInput } from '~/server/trpc/routers/shop/machineryAccessory'
import type { Pagination } from '~/types'

const KEY = 'shop'

type _Where = Record<string, unknown>
type Where = Ref<_Where>

export default () => {
  const { $trpc } = useNuxtApp()
  const { cartId } = useShopCart()

  return {
    machineryQuery: {
      getAllReleasedForRent: (filters?: MaybeRef<ShopMachineryFiltersInput>, where?: Where, pageSize = 12) => useInfiniteQuery({
        queryFn: ({ pageParam }) => $trpc.shop.machinery.getAllReleasedForRent.query({
          filters: unref(filters),
          where: unref(where),
          pagination: { skip: pageParam, take: pageSize },
        }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, lastPageParam) => {
          if (lastPage.length === 0) {
            return undefined
          }
          return lastPageParam + 12
        },
        queryKey: [KEY, 'machinery', filters, where],
      }),
      getAllTypes: () => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllTypes.query(),
        queryKey: [KEY, 'machineryType'],
      }),
      getAllTypesAndCategories: (where?: Where) => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllTypesAndCategories.query(unref(where)),
        queryKey: [KEY, 'machineryType', where],
      }),
      getAllDrives: () => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllDrives.query(),
        queryKey: [KEY, 'machineryDrive'],
      }),
      getReleasedForRentByAggregation: (payload: ShopGroupAggregateMachinery) => useQuery({
        queryFn: () => $trpc.shop.machinery.getReleasedForRentByAggregation.query({ payload }),
        queryKey: [KEY, 'machinery', 'getReleasedForRentByAggregation', payload],
      }),
      getMachineryVersionsForRent: (payload: ShopGroupAggregateMachinery, rentalPeriod: Ref<{ start: Date, end: Date } | undefined>, filters?: MaybeRef<ShopMachineryFiltersInput>) => useQuery({
        queryFn: () => $trpc.shop.machinery.getMachineryVersionsForRent.query({ payload, rentalPeriod: rentalPeriod.value, filters: unref(filters) }),
        queryKey: [KEY, 'machinery', 'getMachineryVersionsForRent', payload, rentalPeriod, filters],
      }),
    },
    cartQuery: {
      getById: (id?: MaybeRef<string | undefined>, enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: async () => {
          const cart = await $trpc.shop.cart.getById.query({ id: unref(id) })
          cartId.value = cart.id
          return cart
        },
        enabled: unref(enabled),
        queryKey: [KEY, 'cart', id, enabled],
      }),
      getUserDataForCart: () => useQuery({
        queryFn: () => $trpc.shop.cart.getUserDataForCart.query(),
        queryKey: ['customerModule', 'account', 'self'],
      }),
    },
    machineryAccessoryQuery: {
      getAllReleasedForSale: (filters?: MaybeRef<ShopMachineryAccessoryFiltersInput>, where?: Where, pagination?: MaybeRef<Pagination>) => useQuery({
        queryFn: () => $trpc.shop.machineryAccessory.getAllReleasedForSale.query({
          filters: unref(filters),
          where: unref(where),
          pagination: unref(pagination),
        }),
        queryKey: [KEY, 'machineryAccessory', filters, where, pagination],
      }),
    },
  }
}
